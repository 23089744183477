import BreveStudioLogo from './images/BreveStudioLogo.png';
import './App.css';

function App() {
  return (
    <>
      <div id="App">
        <div className="soon">COMING SOON · 2023</div>
        <div className="App-breve-logo">
          <img className="breve-logo" alt='breve logo' src={BreveStudioLogo} />
        </div>
        <div className="mad-spain">Madrid - Spain</div>
      </div>
    </>
  );
}

export default App;
